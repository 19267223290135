<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.closing_days.title") }}
    </div>
    <p>
      {{ $t('role.planning.closing_days.desc') }}
    </p>
    <div class="row">
      
      <div class="col">
        <CInputCheckbox
                    :label="$t('common.days.mon')"
                    inline
                    custom
                    :checked.sync="closing_days.Mon"
                  /></div>
      <div class="col"><CInputCheckbox
                    :label="$t('common.days.tue')"
                    inline
                    custom
                    :checked.sync="closing_days.Tue"
                  /></div>
      <div class="col"><CInputCheckbox
                    :label="$t('common.days.wed')"
                    inline
                    custom
                    :checked.sync="closing_days.Wed"
                  /></div>
      <div class="col"><CInputCheckbox
                    :label="$t('common.days.thu')"
                    inline
                    custom
                    :checked.sync="closing_days.Thu"
                  /></div>
      <div class="col"><CInputCheckbox
                    :label="$t('common.days.fri')"
                    inline
                    custom
                    :checked.sync="closing_days.Fri"
                  /></div>
      <div class="col"><CInputCheckbox
                    :label="$t('common.days.sat')"
                    inline
                    custom
                    :checked.sync="closing_days.Sat"
                  /></div>
      <div class="col"><CInputCheckbox
                    :label="$t('common.days.sun')"
                    inline
                    custom
                    :checked.sync="closing_days.Sun"
                  /></div>
      <div class="col">
        <CButton size="sm" color="primary" block :disabled="hasChanged" @click="onModalOpen"
            ><b>{{ $t('common.editor.button.saveEdited') }}</b></CButton
          >
        <CModal
          :title="$t('role.planning.closing_days.modal.title')"
          :show.sync="modalShow"
          :closeOnBackdrop="false"
          :centered="true"
        >
          <p>
            {{ $t('role.planning.closing_days.modal.subtitle') }}
          </p>
          <p>
            {{ $t('role.planning.closing_days.modal.text') }}
          </p>
          <template #footer>
            <CButton color="primary" @click="onModalConfirm"
              >{{ $t('common.editor.button.confirm') }}</CButton
            >
            <CButton color="primary" variant="outline" @click="onModalClose"
              >{{ $t('common.editor.button.cancel') }}</CButton
            >
          </template>
        </CModal>
      </div>
    </div>
    <hr>
    <div class="card-title mb-3">
      {{ $t("role.planning.closing.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
            :visible=false
          ></e-column>
          <e-column
            field="name"
            headerText="Nome"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="start"
            headerText="Dal"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            type="date"
            :format="tsFormat"
          ></e-column>
          <e-column
            field="end"
            headerText="Al"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            type="date"
            :format="tsFormat"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManagerNew, GetDataManager } from "../../../ds/index";
import TB from "../../../common/TB";

export default {
  components: {
    TB,
  },
  data() {
    let table_config = {
        data: GetDataManagerNew("role_planningclose", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            "name",
            "id",
          ],
        },
        reload_on_save: true,
      };
    if (this.$store.getters["connections/havePermission"]("LOGISTICS_EDIT")){
        table_config['editSettings']= {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
        };
        table_config['toolbar']= ["Add", "Edit", "Update", "Cancel"];
    }else{
      table_config['editSettings']= {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        };
        table_config['toolbar']= [];
    }
    let closing_days = {}
    return {
      modalShow: false,
      closing_days: closing_days,
      tb: table_config,
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy" },
    };
  },
  created() {
    this.closing_days = {...this.$store.state.role.closing_days}
  },
  computed: {
    hasChanged() {
      return JSON.stringify(this.closing_days) === JSON.stringify(this.$store.state.role.closing_days);
    },
  },
  methods: {
    onModalOpen() {
      this.modalShow = true;
    },
    onModalClose() {
      this.modalShow = false;
    },
    onModalConfirm() {
      const dm = GetDataManager("auth_role", []);

      const params = {
        id: this.$store.state.role.id,
        closing_days: this.closing_days
      };

      dm.update("", params).then(
        (response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('role.planning.closing_days.messages.ok'),
            color: "success",
            autohide: true,
          });
          // console.log(response)
          this.$store.commit("setRole", response);
          this.modalShow = false;
        },
        (reason) => {
          this.$store.dispatch("toaster/add", {
            title: this.$t('common.editor.messages.ko.title'),
            text: this.$t('role.planning.closing_days.messages.ko'),
            color: "info",
            autohide: true,
          });
        }
      );
    },
  },
};
</script>
